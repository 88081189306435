import { Button, Card, Grid, Typography, } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "../../../components/Form";
import { dateToISO, isoToDate, } from "../../../utils";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import { ExcelIcon, } from "./../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";
import NewDataTable from "../../../components/NewDataTable";

let eventsCount = 0;
let fluidChangedCount = 0;

export default function UnitHourHistory({ unitId }) {

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);

  const { get, post, getFile } = useFetch();
  const { CanEvaluate } = useRoot();

  const [unitHourData, setUnitHourData] = useState([]);
  const [compTypes, setCompTypes] = useState([]);
  const [localData, setLocalData] = useState(0);
  const [originalUHT, setOriginalUHT] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({ IsFluidChange: true, });

  // load unit hour history
  async function loadUnitHourHistoryData(UnitId) {
    const qp = UnitId || unitId;

    if (qp > 0) {
      const resData = await get(`${DETAILS_APIS.UNIT_HOUR_HISTORY}?UnitId=${qp}`);
      if (resData) {
        const compTypesTemp = Object.keys(
          resData.reduce((acc, item) => {
            item.customId =
              (item?.ComponentId || "") +
              (item?.EventLogId || "") +
              (item?.SampleNumber || "");
            if (item?.Component && !acc[item.Component]) {
              acc[item.Component] = true;
            }
            return acc;
          }, {})
        )
          .sort((a, b) => a.localeCompare(b))
          .map((x) => ({ text: x, value: x }));

        compTypesTemp.unshift({ text: "All Components", value: 0 });
        setCompTypes(compTypesTemp);

        const filteredEventCount = resData.filter((item) => item.Event !== null);
        eventsCount = filteredEventCount.length;
        const filteredFluidChangedCount = resData.filter(
          (item) => item.IsFluidChange
        );

        fluidChangedCount = filteredFluidChangedCount.length;
        let dataSource = (resData || []).map((x, i) => ({
          ...x,
          keyProp: i + Math.random(),
        }));

        if (data?.FluidHours === false) {
          let val = dataSource.filter(item => item.IsFluidChange === false).map((x, i) => ({
            ...x,
            keyProp: i + Math.random(),
          }))
          setUnitHourData(val)
        }

        if (data?.component !== 0) {
          let val = dataSource.filter(item => item.Component === data?.component).map((x, i) => ({
            ...x,
            keyProp: i + Math.random(),
          }))
          setUnitHourData(val)
        }
        if (data?.component === 0) {
          setUnitHourData(dataSource)
        }
        setOriginalUHT(dataSource);
      }
    }
  }

  function excelClick() {
    getFile(`${DETAILS_APIS.EXPORT_UNIT_HOUR_HISTORY}?UnitId=${unitId || localData?.UnitId}`, "UnitHourHistory");
  }

  const compareArr = (arr1, arr2) => {
    let arr3 = [];

    for (let i = 0; i < arr1?.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        arr3.push(arr1[i])
      }
    }
    return arr3;
  }

  async function saveEditCellsData() {
    let a1 = [...unitHourData];
    let a2 = [...originalUHT];

    Reflect.deleteProperty(a1, 'keyProp');
    Reflect.deleteProperty(a2, 'keyProp');

    a1.forEach((element) => { delete element.keyProp; });
    a2.forEach((element) => { delete element.keyProp; });

    const resp = compareArr(a1, a2);
    const payload = resp.map(newRow => {
      return {
        ...newRow,
        Sampled: dateToISO(newRow?.Sampled),
        EventLogId: newRow.EventLogId > 0 ? (newRow.EventLogId) : 0,
        ComponentReading: ((newRow.ComponentReading >= 0) && (newRow?.ComponentReading !== "")) ? (newRow.ComponentReading) : null,
        MeterReading: ((newRow.MeterReading >= 0) && (newRow?.MeterReading !== "")) ? (newRow.MeterReading) : null,
        FluidHours: ((newRow.FluidHours >= 0) && (newRow?.FluidHours !== "")) ? (newRow.FluidHours) : null,
        UnitId: unitId || localData?.UnitId || "0",
      }
    })
    if (payload?.length > 0) {
      const responseData = await post(DETAILS_APIS.EDIT_SAMPLE_HOUR, payload);
      if (responseData !== 0) {
        if (localData?.UnitId > 0) {
          loadUnitHourHistoryData(localData?.UnitId);
          localStorage.setItem("unit-hour-history-resp", JSON.stringify({ ...localData, resp: true }));
          window.dispatchEvent(new Event('storage'));
        }
        else {
          loadUnitHourHistoryData();
        }
      }
    }
  }

  const handleEmptyClick = () => {
    const fineData = unitHourData?.map((item, i) => {
      return {
        ...item,
        keyProp: i + Math.random(),
        MeterReading: data?.MeterReadings === true ? (item?.MeterReading == 0 ? null : item?.MeterReading) : item?.MeterReading,
        FluidHours: data?.FluidHoursSave === true ? (item?.FluidHours == 0 ? null : item?.FluidHours) : item?.FluidHours,
        ComponentReading: data?.isComponentHours === true ? (item?.ComponentReading == 0 ? null : item?.ComponentReading) : item?.ComponentReading,
      }
    });
    setUnitHourData(fineData)
  }

  useEffect(() => {
    if (data?.FluidHours === false) {
      let val = unitHourData.filter(item => item.IsFluidChange === false).map((x, i) => ({
        ...x,
        keyProp: i + Math.random(),
      }))
      setUnitHourData(val)
    }
    else {
      setUnitHourData(originalUHT)
    }
  }, [data?.FluidHours,])

  useEffect(() => {
    if (data?.component !== 0) {
      let val = originalUHT.filter(item => item.Component === data?.component).map((x, i) => ({
        ...x,
        keyProp: i + Math.random(),
      }))
      setUnitHourData(val)
    }
    else if (data?.component === 0) {
      setUnitHourData(originalUHT)
    }

  }, [data?.component,]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleChange = debounce((event, row) => {
    setUnitHourData(unitHourData.map((item) => (row.original.customId === item.customId ? { ...item, [event.target.name]: event.target.value } : { ...item })));
  }, 1000); // Debounce delay of 1000ms

  const tblOptions1 = {
    columns: GetColumns(data, setUnitHourData, unitHourData, handleChange),
    dataSource: unitHourData || [],
    getRowId: (originalRow) => originalRow.keyProp,
    enablePagination: false,
    sx: { height: "80dvh" },
    enableColumnActions: false,
    enableEditing: true,
    editDisplayMode: 'table',
    state: { columnVisibility }, //manage columnVisibility state
    onColumnVisibilityChange: setColumnVisibility,
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit", color: "white" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff", color: "white" },
      }),
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx:
          cell.column.columnDef.accessorKey === "Sampled" && row.original.IsEvent ? {
            backgroundColor: "teal !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px", color: "white" },
          } : cell.column.columnDef.accessorKey === "Component" && row.original.IsEvent && row.original.AlertColour ? {
            backgroundColor: "#c71585 !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px" },
          } : cell.column.columnDef.accessorKey === "Event" && row.original.IsEvent && row.original.AlertColour ? {
            backgroundColor: "#c71585 !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px" },
          } : cell.column.columnDef.accessorKey === "Sampled" && row.original.IsEvent && row.original.AlertColour ? {
            backgroundColor: "#c71585 !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px" },
          } : cell.column.columnDef.accessorKey === "ComponentChanged" && row.original.IsEvent && row.original.AlertColour ? {
            backgroundColor: "#c71585 !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px" },
          } : cell.column.columnDef.accessorKey === "Event" && row.original.IsEvent && row.original.AlertColour ? {
            backgroundColor: "#c71585 !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px" },
          } : cell.column.columnDef.accessorKey === "Sampled" && row.original.IsEvent && row.original.AlertColour ? {
            backgroundColor: "#c71585 !important",
            color: "white!important",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px", color: "white!important" },
          } : (cell.column.columnDef.accessorKey === "IsMeterChange" || cell.column.columnDef.accessorKey === "Component" || cell.column.columnDef.accessorKey === "Event") && row.original.IsEvent && row.original.IsMeterChange ? {
            backgroundColor: "#c71585 !important",
            color: "white",
            py: 0.25,
            border: "0.5px solid #d0d0d0",
            "& .MuiInputBase-input": { height: "8px" },
          }
            // Commented to make cell red if previous value is greater
            // : (cell.column.columnDef.accessorKey === "MeterReading" && row?.original?.SampleNumber > 0 && unitHourData[row?.index + 1]?.SampleNumber > 0 && ((unitHourData[row?.index + 1])?.MeterReading > row?.original?.MeterReading)) ? {
            //   backgroundColor: "red !important",
            //   color: "white",
            //   py: 0.25,
            //   border: "0.5px solid #d0d0d0",
            //   "& .MuiInputBase-input": { height: "8px" },
            // } 
            : (cell.column.columnDef.accessorKey === "IsComponentChange" || cell.column.columnDef.accessorKey === "Component" || cell.column.columnDef.accessorKey === "Event") && row.original.IsEvent && row.original.IsComponentChange ? {
              backgroundColor: "#fa8072 !important",
              color: "white",
              py: 0.25,
              border: "0.5px solid #d0d0d0",
              "& .MuiInputBase-input": { height: "8px" },
            } : (cell.column.columnDef.accessorKey === "IsFluidChange" || cell.column.columnDef.accessorKey === "Component" || cell.column.columnDef.accessorKey === "Event") && row.original.IsEvent && row.original.IsFluidChange ? {
              backgroundColor: "teal !important",
              color: "white",
              py: 0.25,
              border: "0.5px solid #d0d0d0",
              "& .MuiInputBase-input": { height: "8px" },
            } : cell.column.columnDef.accessorKey === "SampleNumber" && row.original.AlertColour ? {
              backgroundColor: row.original.AlertColour.toLowerCase() + "!important",
              color: "white",
              py: 0.25,
              border: "0.5px solid #d0d0d0",
              "& .MuiInputBase-input": { height: "8px" },
            } : {
              py: 0.25,
              color: "black",
              fontWeight: "500",
              border: "0.5px solid #d0d0d0",
              "& .MuiInputBase-input": {
                height: "8px", fontWeight: "500",
              },
            },
      }
    }
  };

  useEffect(() => {
    (async function () {
      let parseData = JSON.parse(localStorage.getItem("unit-hour-history"))
      const queryParameter = window.location.search.replace("?UnitId=", "")

      if (parseData?.UnitId > 0) {
        setLocalData(parseData);
        return loadUnitHourHistoryData(parseData?.UnitId);
      }
      else if (queryParameter > 0) {
        return loadUnitHourHistoryData(queryParameter);
      }
      else {
        loadUnitHourHistoryData();
      }
    })();
  }, []);

  useEffect(() => {
    if (data?.AllowEditing === true) {
      let meterList = document.getElementsByName("MeterReading");
      let componentList = document.getElementsByName("ComponentReading");
      let fluidlist = document.getElementsByName("FluidHours");

      if (meterList?.length > 0) {
        for (let i = 0; i < meterList?.length; i++) {
          meterList[i].setAttribute("autocomplete", "off");
          componentList[i].setAttribute("autocomplete", "off");
          fluidlist[i].setAttribute("autocomplete", "off");
        }
      }
    }
  });

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F10") {
        event.preventDefault();
        handleEmptyClick()
      }
      if (event.key === "F11") {
        event.preventDefault();
        excelClick()
      }
      if (event.key === "F12") {
        event.preventDefault();
        saveEditCellsData()
      }
    },
    [unitHourData, originalUHT]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid container spacing={1} sx={{ minWidth: "90vw" }}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Form
                  sections={FormSections(excelClick, data, saveEditCellsData, CanEvaluate, handleEmptyClick)}
                  data={data}
                  dispatch={dispatch}
                  masterData={{ compTypes }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            There are {eventsCount} events for this unit: Fluid Changed{" "}
            {fluidChangedCount}
          </Typography>
          <NewDataTable {...tblOptions1} />

        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(data, setUnitHourData, unitHourData, handleChange) {

  return [
    {
      header: "Component",
      accessorKey: "Component",
      maxSize: 150,
      size: 150,
      enableEditing: false,
    },
    {
      header: "Event",
      accessorKey: "Event",
      maxSize: 120,
      size: 150,
      enableEditing: false,
    },
    {
      header: "Meter Changed",
      accessorKey: "IsMeterChange",
      accessorFn: (dataRow) => (dataRow.IsMeterChange ? "Yes" : ""),
      maxSize: 80,
      size: 80,
      enableEditing: false,
    },
    {
      header: "Comp Changed",
      accessorKey: "IsComponentChange",
      accessorFn: (dataRow) => (dataRow.IsComponentChange ? "Yes" : ""),
      maxSize: 80,
      size: 80,
      enableEditing: false,
    },
    {
      header: "Fluid Changed",
      accessorKey: "IsFluidChange",
      hide: !data.FluidHours,
      accessorFn: (dataRow) => (dataRow.IsFluidChange ? "Yes" : ""),
      maxSize: 80,
      size: 80,
      enableEditing: false,
    },
    {
      header: "Sampled",
      accessorKey: "Sampled",
      accessorFn: (dataRow) => isoToDate(dataRow.Sampled),
      maxSize: 80,
      size: 80,
      sortable: false,
      enableEditing: (data?.AllowEditing === true) ? true : false,
      valueFormatter: ({ value }) => (value == null ? "" : value),
      placeHolder: "",
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        autoComplete: "off",
        placeholder: '',
        onKeyUp: (event) => {
          if (event.key === 'ArrowLeft') {
            let a = event.target.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = 1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowRight') {
            let a = event.target.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowUp') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[5].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }

          if (event.key === 'ArrowDown') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[5].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
        },
        onBlur: (event) => {
          // setUnitHourData(unitHourData.map((item) => (row.original.keyProp === item.keyProp ? { ...item, [event.target.name]: event.target.value } : { ...item })));
          setUnitHourData(unitHourData.map((item) => (row.original.customId === item.customId ? { ...item, [event.target.name]: event.target.value } : { ...item })));
        },
        onChange: (event) => {
          handleChange(event, row);
        },
      }),
    },
    {
      header: "Meter Reading",
      accessorKey: "MeterReading",
      enableEditing: data?.AllowEditing === true ? true : false,
      maxSize: 80,
      size: 80,
      valueFormatter: ({ value }) => (value == null ? "" : value),
      placeHolder: "",
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        autoComplete: "off",
        placeholder: '',
        onKeyUp: (event) => {
          if (event.key === 'ArrowLeft') {
            let a = event.target.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = 1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowRight') {
            let a = event.target.parentElement.parentElement.parentElement.nextElementSibling.nextElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowUp') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[6].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }

          if (event.key === 'ArrowDown') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[6].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
        },
        onBlur: (event) => {
          // setUnitHourData(unitHourData.map((item) => (row.original.keyProp === item.keyProp ? { ...item, [event.target.name]: event.target.value } : { ...item })));
          setUnitHourData(unitHourData.map((item) => (row.original.customId === item.customId ? { ...item, [event.target.name]: event.target.value } : { ...item })));
        },
        onChange: (event) => {
          handleChange(event, row);
        },
      }),
    },
    {
      header: "Unit Hours",
      accessorKey: "UnitHours",
      maxSize: 80,
      size: 80,
      sortable: false,
      enableEditing: false,
    },
    {
      header: "Component Hours",
      accessorKey: "ComponentReading",
      maxSize: 80,
      size: 80,
      sortable: false,
      enableEditing: data?.AllowEditing === true ? true : false,
      placeHolder: "",

      valueFormatter: ({ value }) => (value == null ? "" : value),
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        autoComplete: "off",
        placeholder: '',
        onKeyUp: (event, i) => {
          if (event.key === 'ArrowLeft') {
            let a = event.target.parentElement.parentElement.parentElement.previousElementSibling.previousElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = 1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowRight') {
            let a = event.target.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowUp') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[8].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }

          if (event.key === 'ArrowDown') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[8].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
        },
        onBlur: (event) => {
          // setUnitHourData(unitHourData.map((item) => (row.original.keyProp === item.keyProp ? { ...item, [event.target.name]: event.target.value } : { ...item })));
          setUnitHourData(unitHourData.map((item) => (row.original.customId === item.customId ? { ...item, [event.target.name]: event.target.value } : { ...item })));
        },
        onChange: (event) => {
          handleChange(event, row);
        },
      }),
    },
    {
      header: "Fluid Hours",
      accessorKey: "FluidHours",
      maxSize: 80,
      size: 80,
      enableEditing: data?.AllowEditing === true ? true : false,
      sortable: false,
      muiEditTextFieldProps: ({ cell, column, row, table }) => ({
        autoComplete: "off",
        placeholder: '',
        onKeyUp: (event) => {
          if (event.key === 'ArrowLeft') {
            let a = event.target.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = 1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowRight') {
            let a = event.target.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
          if (event.key === 'ArrowUp') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.previousElementSibling;
            a = a.childNodes[9].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }

          if (event.key === 'ArrowDown') {
            let a = event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling;
            a = a.childNodes[9].childNodes[0].childNodes[0].childNodes[0];
            a.tabIndex = -1;
            a.focus();
            a.select();
          }
        },
        onBlur: (event) => {
          // setUnitHourData(unitHourData.map((item) => (row.original.keyProp === item.keyProp ? { ...item, [event.target.name]: event.target.value } : { ...item })));
          setUnitHourData(unitHourData.map((item) => (row.original.customId === item.customId ? { ...item, [event.target.name]: event.target.value } : { ...item })));
        },
        onChange: (event) => {
          handleChange(event, row);
        },
      }),
    },
    {
      header: "Sample Number",
      accessorKey: "SampleNumber",
      maxSize: 100,
      size: 100,
      sortable: false,
      enableEditing: false,
      flex: 1,
    },
  ]
}

function FormSections(excelClick, data, saveEditCellsData, CanEvaluate, handleEmptyClick) {
  return [
    {
      fields: [
        {
          xsCol: 5,
          group: [
            {
              name: "component",
              label: "Component",
              type: "dropdown",
              xsCol: 3,
              required: true,
              alignSelf: "end",
              optionConfigs: {
                name: "compTypes",
              },
            },
            {
              name: "FluidHours",
              label: "Fluid Hours",
              type: "switch",
            },
            {
              name: "AllowEditing",
              label: "Allow Editing",
              type: "switch",
              className: "red",
            },
            // {
            //   name: "SampledDate",
            //   label: "+Sampled",
            //   type: "switch",
            // },
            {
              mdCol: 3,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    onClick={excelClick}
                    startIcon={<ExcelIcon />}
                  >
                    Export (F11)
                  </Button>
                );
              },
            },
          ],
        },
        data?.AllowEditing === true
          ? {
            xsCol: 7,
            group: [
              {
                xsCol: 12,
                component: function () {
                  return (
                    <Typography>
                      If anything is UNKNOWN, e.g. Meter Reading, then make the value
                      EMPTY and NOT ZERO; zero means that the value is known and brand
                      new
                    </Typography>
                  )
                },
              },
              {
                xsCol: 12,
                sx: { paddingTop: "1px", },
                group: [
                  {
                    component: function () {
                      return (
                        <Typography sx={{ mt: "4px" }}>
                          Set 0s to Empty
                        </Typography>
                      )
                    },
                  },
                  {
                    name: "MeterReadings",
                    label: "Meter Readings",
                    type: "switch",
                  },
                  {
                    name: "isComponentHours",
                    label: "Component Hours",
                    type: "switch",
                  },
                  {
                    name: "FluidHoursSave",
                    label: "Fluid Hours",
                    type: "switch",
                  },

                  {
                    component: function () {
                      return (
                        <Button
                          variant="outlined"
                          onClick={handleEmptyClick}
                        >
                          Update to Empty (F10)
                        </Button>
                      );
                    },
                  },
                  CanEvaluate && {
                    component: function () {
                      return (
                        <Button
                          variant="contained"
                          onClick={saveEditCellsData}
                        >
                          Save (F12)
                        </Button>
                      );
                    },
                  },
                ],
              },
            ],
          }
          : {
            type: "null",
          }]
    }]
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const SearchFilterDefaults = {
  AllowEditing: false,
  SampledDate: false,
  FluidHours: true,
  MeterReadings: true,
  isComponentHours: true,
  FluidHoursSave: true,
  component: 0,
};
